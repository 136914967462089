.sidebar-contact {
  position: fixed;
  top: 67%;
  right: -350px;
  transform: translateY(-50%);
  width: 343px;
  height: auto;
  padding: 40px;
  background: #fff;
  box-sizing: border-box;
  transition: 0.5s;
}

.sidebar-contact.active {
  right: 0;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.sidebar-contact input,
.sidebar-contact textarea {
  width: 100%;
  height: 36px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}

.sidebar-contact h2 {
  margin: 0 0 20px;
  padding: 0;
}

.sidebar-contact textarea {
  height: 60px;
  resize: none;
}

.submit-button {
  background: #ff8038;
  color: #fff;
  cursor: pointer;
  border: none;
  font-size: 18px;
  width: 100%;
  padding: 5px 0 5px 0;
}

.toggle {
  position: absolute;
  height: 48px;
  width: 48px;
  text-align: center;
  cursor: pointer;
  background: #ff8038;
  top: 0;
  border: none;
  box-shadow: 5px 0px 8px 2px rgba(0, 0, 0, 0.168627451);
  left: -48px;
  line-height: 48px;
}
.sidebar-contact input:focus{
  border:2px solid #ff8038 !important;
  box-shadow: none;
}

.sidebar-contact textarea:focus{
  border:2px solid #ff8038 !important;
  box-shadow: none;
}
.feedback_input input{
  height: 80px
px
 !important;
    color: #525b75 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif;
  text-transform: none !important;
}
@media (max-width: 768px) {
  .sidebar-contact {
    width: 100%;
    height: 100%;
    left: -100%;
  }
  .sidebar-contact .toggle {
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
  }
  .sidebar-contact.active .toggle {
    top: 0;
    right: 0;
    transform: translateY(0);
  }
  .scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .content {
    padding: 50px 50px;
  }
}/*# sourceMappingURL=feedback.css.map */