@media (min-width: 992px) {
    .modal-lg-extra-width,
    .modal-xl {
        max-width: 1194px !important;
    }
}

.form-calc input {
    height: 31px;
    border-radius: 5px;
    box-shadow: none !important;
}

.w-90 {
    width: 92px !important;
}

.margin-0 {
    margin: 0px;
}

.color-thumb {
    padding: 5px 20px;
    background-color: #edebeb !important;
    border-radius: 5px;
    color: #ff9152;
    margin-right: 5px;
}

.summary-news {
    display: block;
    width: 100%;
    height: 65px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.summary-news-div {
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: rgb(189 205 239 / 49%) 3px 4px 0 0;
    border-radius: 10px;
}

.h-127 {
    border-radius: 5px;
}

.red {
    color: red !important;
}

.green {
    color: green !important;
}

.day-active {
    background-color: #ff7900 !important;
    color: #fff !important;
}

.white {
    color: #fff !important;
}

.text-end {
    text-align: end;
}

.btn-close-provider {
    position: absolute !important;
    top: 29px !important;
    right: 35px !important;
    padding: 3px 7px 10px 7px !important;
}

.trade_table_close {
    position: absolute !important;
    top: 9px !important;
    right: 39px !important;
    padding: 3px 7px 10px 7px !important;
}

.trade_table_close2 {
    position: absolute !important;
    top: 3px !important;
    right: 39px !important;
    padding: 3px 7px 10px 7px !important;
}

.white {
    color: #fff;
}

.white:hover {
    color: #fff !important;
}

.ml-auto {
    margin-left: auto;
}

.dropdown-search p {
    border-bottom: 1px solid black;
    padding-left: 16px;
    cursor: pointer;
    margin-bottom: 0px;
}

.dropdown-search {
    background-color: #ffffff;
    border: 1px solid #f4e7e7;
    border-radius: 6px;
    margin-top: 3px;
    position: absolute;
    width: 49%;
    padding: 0px 5px;
    overflow-y: scroll;
    max-height: 146px;
}

.value-bx1 span {
    border-radius: 5px;
    height: 38px;
}

.value-bx1 span input {
    height: 38px;
    border: none;
    box-shadow: none !important;
    /* width: 89%; */
    padding-left: 20px;
}

.value-bx1 span {
    padding: opx !important;
}

.currency-code {
    position: absolute;
    left: 8px;
    top: 10px;
}

.fullc-or {
    padding: 13px;
    margin-top: 5px;
}

spn.sroce-nm {
    color: #000;
    padding-left: 5px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
}

.button-padding {
    padding: 3px 7px 10px 7px !important;
    background: #ff7900;
    border: 1px solid #fff;
    color: #fff;
    margin: -0.5rem -0.5rem -0.5rem auto;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    border-radius: 4px;
}

.backdrop {
    backdrop-filter: brightness(0.5) !important;
    z-index: 11111;
}

.z-indez-2000 {
    z-index: 2000;
}

.backdrop-1 {
    background: rgb(0 0 0 / 51%);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(0px);
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18);
    /* backdrop-filter: brightness(0.5) !important; */
}

.Toastify {
    z-index: 111111111 !important;
}

.toggle-chart {
    width: 47px !important;
    height: 26px !important;
    color: #ff790000 !important;
    background-color: #ff7900 !important;
    border: none !important;
}

html,
body,
#root,
.App {
    margin: 0;
}

.App {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chart-container {
    flex: 1;
}

.border-top-1 {
    border-top: 1px solid #cbd0dd;
}

.portButton {
    padding: 6px;
    margin-top: 10px;
}

.date-picker {
    width: 100%;
    border: 1px solid #ced4da;
}

.color-msg-err {
    color: rgb(177, 10, 10);
    font-size: 13px;
}

p.err-msg {
    width: 100%;
    text-align: center;
    color: #ff8989;
}

.input-invalid {
    --tw-border-opacity: 1;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68/var(--tw-ring-opacity));
    border-color: rgb(239 68 68/var(--tw-border-opacity));
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.input-select-forms {
    appearance: none;
    border-radius: 0.375rem;
    border-width: 1px;
    padding: 0rem 0rem;
    transition-duration: .15s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    width: 100%;
}

.candle-chart-link {
    color: #376ee7 !important;
    font-size: 14px;
    margin-left: auto;
    text-decoration: underline;
    text-transform: none;
}

.css-qbdosj-Input {
    height: 29px
}

.css-13cymwt-control {
    height: 35px;
}

.cursore-pointer {
    cursor: pointer;
}

#brow {
    position: absolute;
    color: black;
    max-height: 262px;
    /* min-height: ; */
    width: 300px;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #00000033;
    z-index: 99;
}

#brow option {
    padding-left: 20px;
    padding-top: 3px;
    padding-bottom: 2px;
    cursor: pointer;
    font-size: 13px;
}

.border-red {
    border: 2px solid #ff1c1c !important;
}

.open-trade {
    background-color: white;
    color: black;
    font-size: 17px;
    padding: 0px 20px;
}

.tradingview_5327b-css {
    height: 625px !important;
    overflow: hidden
}

div[data-ng-controller=intrusiveShowmanUserControlController] {
    display: none;
}

div[data-ng-controller=popupAdsUserControlController] {
    display: none;
}

div.modal-backdrop {
    display: none;
}

.pagination-button {
    background-color: #ffffff00;
    /* color: #000000; */
    border: none;
    padding: 0px 20px;
    cursor: pointer;
}

.country-Active {
    background-color: #edebeb !important;
    color: #ff9152 !important;
}

.pe-auto {
    cursor: pointer;
}

.admin-country-w-h {
    padding: 5px 29px;
}

.country-width {
    width: 83%;
    margin-top: 9px;
    border-bottom: 1px dotted #ff8038;
}

.w-14 {
    width: 14%;
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}


/* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    transform: rotate(-180deg); */

.color-grey {
    background-color: #dfdede !important;
}

.joyrid-content-comon {
    padding-top: 0px !important;
}

.react-joyride__tooltip button {
    background-color: #ff8038 !important;
    color: white !important;
    border-radius: 4px !important;
}

.react-joyride__tooltip div {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
}

.react-joyride__tooltip p {
    line-height: 1.3;
    text-align: center;
}


/*     text-transform: none; */

.react-joyride__tooltip h4 {
    text-transform: none !important;
}

button.btn-close-candle {
    background: #131722;
    border: 1px solid #fff;
    color: #fff;
}

button.btn-close-candle:hover {
    background: #ffffff;
    color: #000;
}

.btn-close-candle {
    position: absolute;
    z-index: 11111;
    top: 2px !important;
    right: 100px !important;
}

.admin-btn-main {
    padding-top: 4px;
    padding-bottom: 4px;
}

.stockOption {
    z-index: 11;
    max-height: 131px;
    padding: 6px 14px;
    width: 30%;
    border-bottom: 1px solid black;
    padding-left: 16px;
    cursor: pointer;
    margin-bottom: 0px;
}

.stockOption_cal {
    width: 63% !important;
    /* height: 124px; */
    position: absolute;
    top: 76px;
}

.padding-1rem {
    padding: 0rem 1rem 1rem 1rem !important;
}

.border-radius-10 {
    border-radius: 10px;
}

.overview-header-text {
    font-size: 22px !important;
    margin-left: 20px;
    padding-top: 19px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .overview-header-text {
        font-size: 22px !important;
        margin-left: 0px;
        padding-top: 0px;
    }
}

.no-data {
    font-size: 1rem;
    color: #000000b3;
}

input {
    /* text-transform: capitalize !important; */
}

.input_font-Size {
    font-size: 12px !important;
    /* text-transform: capitalize; */
    font-family: 'Montserrat', sans-serif;
}

textarea {
    height: 80px !important;
    color: #525b75 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif;
}

.chartLoader {
    position: absolute;
    top: 10%;
    left: 50%;
    right: 50%;
    z-index: 111111111;
}

.option-width {
    width: 100px;
}

.chart-overView {
    height: 355px !important;
    overflow: hidden;
}

.chartSel {
    line-height: 1.5rem !important;
}

select.chartSel1 {
    width: 100px;
    border: 2px solid #00000026;
    height: 27px;
    color: #000000d1;
    border-radius: 3px;
}

.edit-btn-trade {
    border: 1px solid #dee2e6;
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    border-radius: 0.375rem;
    font-size: 14px;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
:root {
    --header-height: 3rem;
    --nav-width: 60px;
    --first-color: #eff3f9;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*,
::before,
::after {
    box-sizing: border-box
}

.a .header {
    width: 100%;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    /* background-color: var(--white-color); */
    z-index: var(--z-fixed);
    transition: .5s
}

.a .header_toggle {
    color: black;
    font-size: 1.5rem;
    cursor: pointer
}

.a .header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.a .header_img img {
    width: 40px
}

.a .l-navbar {
    width: var(--nav-width);
    /* height: 100vh; */
    background-color: #eff3f900;
    padding: 0.5rem 0 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    color: white;
}

.a .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.a ul li {
    padding: 0 15px 0 15px;
    border-bottom: 1px solid #dee2e6 !important;
}

.a .nav_logo,
.a .nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 0;
}

.nav_logo_main {
    display: grid;
    /* grid-template-columns: max-content max-content; */
    align-items: center;
    /* column-gap: 1rem; */
}

.a .nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}

.a .nav_logo-name {
    color: var(--white-color);
    font-weight: 700
}

.a .nav_link {
    position: relative;
    /* color: var(--first-color-light); */
    transition: .3s
}

.a .nav_link:hover {
    color: var(--white-color)
}

.a .nav_icon {
    font-size: 1.25rem
}

.a .show {
    left: 0
}


/* .a .body-pd {
            padding-left: calc(var(--nav-width) + 1rem)
        } */

.a .active {
    color: var(--white-color)
}

.a .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
}

.btn-p {
    padding: 5px 20px;
    background: #f0c5c3;
    color: #ce241a;
    font-family: 'Montserrat', sans-serif;
}

.btn-light-green {
    background: #b3f5da;
    color: #00735f;
}

@media screen and (min-width: 768px) {
    /* body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    } */
    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }
    .header_img {
        width: 40px;
        height: 40px
    }
    .header_img img {
        width: 45px
    }
    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }
    .show1 {
        width: calc(var(--nav-width) + 170px) !important;
    }
    .body-pd {
        padding-left: calc(var(--nav-width) + 188px)
    }
}

.w-81 {
    width: 81%;
}

.gap-1_9 {
    column-gap: 1.3rem;
}

.column_gap-12 {
    column-gap: 12px;
}

.w-151 {
    width: 138px;
}

.toggle-font {
    font-size: 32px;
    font-weight: 500;
}


/*======= market overview ========*/

.left-bar-height-issue {
    height: 879px !important;
    overflow: auto;
}

.lef_side_bar {
    height: 873px;
    overflow: auto;
}

.about-data-lg {
    max-width: 1000px;
}

.lef_side_bar .bi-search {
    position: absolute;
    top: 9px;
    left: 16px;
    color: #000;
}

.lef_side_bar input {
    height: 40px;
    padding-top: 5px;
    border: 1.01979px solid #e2e8f0;
    font-size: 13px;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
}

.tread-vol-bx {
    /* background-color: #eff3f9; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #e2e8f0;
    color: white;
}

.tread-vol-bx .nav-pills .nav-link.active {
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: #ff9152;
    padding: 6px 8px;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 13px;
    border-bottom: 3px solid #ff9152;
    border-radius: 0px;
    margin-right: 13px;
}

.tread-vol-bx .nav-link {
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    padding: 6px 10px;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 13px;
    margin-right: 13px;
}

.data-bx-min img {
    max-width: 35px;
}

.data-bx-min span {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

.data-bx-min {
    position: relative;
}

.price-btn {
    padding: 5px 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: #f0c5c3;
    color: #ce241a;
    font-family: 'Montserrat', sans-serif;
}

.btn-light-green {
    background: #b3f5da;
    color: #00735f;
}

.lef_side_bar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
}

.lef_side_bar::-webkit-scrollbar {
    width: 12px;
    background-color: white;
}

.about-content-bx {
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    padding: 15px 20px;
}

.about-data-tag a {
    display: inline-block;
    list-style: none;
    color: #000;
    padding: 14px 40px;
    font-size: 13px;
    margin-right: 20px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px;
    transition: .25s;
}

.about-data-tag a:hover {
    background-color: #ff9152;
    color: #fff;
}

.abouut-select-data label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.abouut-select-data select {
    height: 45px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.abouut-select-data select:focus {
    border: 1px solid #ff9152;
    box-shadow: none !important;
}

.abouut-select-data th {
    padding: 15px 0px;
}

.abouut-select-data td {
    padding: 15px 0px;
}

.about-table-dat1 {
    background-color: #f7fafc !important;
}

.about-table-dat2 {
    background-color: #f3f7fe !important;
}

.new-feed-bx img {
    /* max-width: 114px; */
    /* height: 105px; */
    margin-top: 13px;
    border-radius: 4px;
}

.new-feed-bx .card {
    padding: 10px;
}

.know-prd-bx {
    border-right: 1px solid #e2e8f0;
    padding: 10px 14px;
}

.know-prd-bx h6 {
    font-size: 12px;
}

.know-price-1 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.know-price-2 {
    color: #f00;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.price-2-clr {
    color: #2bb940;
}

.top-know-price-bx {
    border: none;
    border-radius: 20px;
}

.top-know-price-bx-1 {
    border: 1px solid #e2e8f0;
}

.know-commper-bx {
    border: 2px solid #ced5d9;
    padding: 4px 5px;
    border-radius: 10px;
}

.know-commper-bx-min {
    border-right: 2px solid #ced5d9;
    border-right: 2px solid #ced5d9;
    border: 2px solid #ced5d9;
    padding: 0px 5px;
    border-radius: 6px;
    background-color: white;
}

.know-commper-bx-min label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding-top: 9px;
}

.know-commper-bx-min input {
    font-family: 'Montserrat', sans-serif;
    border: none;
}

.know-commper-bx img {
    border-radius: 30px;
}

.know-commper-bx span {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}

.compare-bx {
    border-bottom: 1px solid #e9eef1;
    position: relative;
}

.comere-icon-bx {
    background-color: #fff;
    position: absolute;
    left: 350px;
    top: -24px;
    border-radius: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-size: 21px;
    color: #ff9152;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    cursor: pointer;
}

.pricing-bx-data .dropdown-toggle {
    border: 1px solid #fff;
    background-color: #ff9152;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

.pricing-bx-data .dropdown-menu {
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    border: 1px solid #e2e8f0;
    padding: 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.min-data-drop-down {
    border: 1px solid #e2e8f0;
    padding: 10px;
    border-radius: 5px;
}

.min-data-drop-down h5 {
    font-size: 13px;
}

.min-data-drop-down p,
span {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}

.min-data-drop-down span input {
    border: 1px solid transparent;
    background-color: transparent;
}

.drop_price input {
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    border: none;
}

.price-data-mr {
    background: none;
    padding: 16px 20px;
    border-radius: 10px;
}

.price-data-mr .table-input {
    border: none !important;
}

.price-data-mr th {
    font-size: 13px;
    border-left: none !important;
    border-right: none !important;
    padding: 15px 20px;
}

.price-data-mr td {
    font-size: 13px;
    border-left: none !important;
    padding: 15px 20px;
}

.price-data-mr h5 {
    font-size: 10px;
}

.price-data-mr-green {
    color: #06af47;
    padding: 0px !important;
}

.pagination-container {
    font-size: 13px;
}

.search-input {
    padding: 0px 20px;
    position: relative;
}

.search-input input {
    border: 2px solid #ced5d9;
    padding: 0px 10px;
}

.search-input-main:focus-visible {
    outline: none
}

.search-input-main:focus {
    border: 2px solid #ff8038 !important;
}

.search-input span {
    position: absolute;
    top: -8px;
    right: 28px;
    color: #f88745a3 !important;
    font-size: 24px;
}

.crypto_side_selected {
    background: linear-gradient(51deg, rgb(29 48 89) 49%, rgba(36, 58, 105, 1) 10%);
    color: white !important;
    border-radius: 4px;
}

.a ul li:hover {
    background: linear-gradient(51deg, rgba(36, 58, 105, 1) 3%, rgba(77, 102, 156, 1) 105%);
    /* background-color: #ff9152; */
    color: rgb(255, 255, 255);
    border-radius: 4px;
}

.crypto_side_selected1 {
    color: #ffffff;
}

.search-input input {
    border: 2px solid #ced5d9;
    padding: 0 39px 0 10px;
    border-radius: 3px;
    font-size: 13px;
    height: 35px;
    /* transition: .25s; */
}

.search-input .span1 svg {
    margin-bottom: 10px;
}

.search-input .span1 {
    background: #fff;
    height: 31px;
    position: absolute;
    right: 5px!important;
    top: 2px!important;
    width: 33px;
}

.coin-name {
    font-size: 20px;
    color: #e8580c;
}

.starting_b {
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding: 0 5px 0 6px;
}

.starting_main {
    color: #525b75;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
}

.open-trade-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: -111;
}

.profit-loss-button {
    font-size: 13px;
    padding: 5px 5px;
    /* margin-top: 10px; */
}

.border-redious-top-left {
    border-top-left-radius: 8px;
}

.tradingview-widget-container-crypto {
    /* width: 702px !important; */
    position: relative;
}

.tradingview_5327b-css-crypto {
    height: 360px;
}

.rowModed>* {
    /* flex-shrink: 0;
   width: 100%;
   max-width: 100%; */
    padding-right: calc(var(--bs-gutter-x) * .1);
    /* padding-left: calc(var(--bs-gutter-x) * .5);
   margin-top: var(--bs-gutter-y); */
}

.rowModed-left>* {
    /* flex-shrink: 0;
   width: 100%;
   max-width: 100%; */
    /* padding-right: calc(var(--bs-gutter-x) * .1); */
    padding-left: calc(var(--bs-gutter-x) * .1);
    /* margin-top: var(--bs-gutter-y); */
}

.rowModed-left-right {
    padding-right: calc(var(--bs-gutter-x) * .0);
    padding-left: calc(var(--bs-gutter-x) * .0);
}

.font-waight-cry {
    font-size: 13px !important;
}

.line-height-0 {
    line-height: 3px;
}

.padding-top7 {
    padding-top: 7px;
}

.active-buy {
    color: #55bd6c !important;
    border-bottom: 3px solid #55bd6c !important;
}

.active-sell {
    color: #f6685e !important;
    border-bottom: 3px solid #f6685e !important;
}

.buyButton {
    background-color: #55bd6c;
}

.sellButton {
    background-color: #f6685e;
}

.height-39 {
    height: 39px !important;
}

.height-39:focus {
    outline: none !important;
    border: none !important;
    background-color: transparent;
}

.select-limit {
    height: 39px !important;
    border: 2px solid #ced5d9;
    padding: 0px 8px;
}

.select-limit:focus {
    outline: none !important;
    border: 2px solid #ced5d9 !important;
}

.css-13cymwt-control {
    border: 2px solid #ced5d9 !important;
}

.css-t3ipsp-control {
    border: 2px solid #ced5d9 !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover {
    border: 2px solid #ced5d9 !important;
}

.border-2px {
    border-left: 2px solid #ced5d9;
    margin: 6px 10px;
}

.show-widgth-button-hide {
    position: absolute;
    z-index: 11111111;
    top: 282px;
    left: 13px;
}

.show-widgth-button-show {
    position: absolute;
    z-index: 11111111;
    top: 282px;
    left: 70px;
}

.show-widgth-button {
    border: 2px solid #ff9152;
    border-radius: 50%;
    background-color: #ff9152;
    color: white;
    padding: 3px 7px;
}

.btn-close-candle {
    position: absolute;
    z-index: 11111;
    top: 2px !important;
    right: 100px !important;
}

.expend-btn {
    padding: 0px 6px;
    font-size: 22px;
}

p.font-waight-cry.line-height-0 {
    line-height: 9px;
    margin-bottom: 2px;
}

button.btn-close-candle:hover {
    background: #ffffff;
    color: #000;
}

.input-err-color {
    border: 2px solid rgb(197 0 0);
}

.p-left-row>* {
    padding-left: calc(var(--bs-gutter-x) * .3);
}

.p-23-20 {
    padding: 23px 20px;
}

.model-738 {
    max-width: 750px;
}

.css-1nmdiq5-menu {
    margin-right: 23!important;
}

.admin-btn-main {
    padding-top: 4px;
    padding-bottom: 4px;
}

.height-36px {
    height: 36px !important;
}


/* Sidebar */

.color-theme {
    color: #ff9152;
    margin-bottom: 5px;
}

.ps-10px {
    padding-left: 6px;
}

.model-sidebar {
    border: 2px solid #e3dfdf;
}

.toggle-button-sideBar {
    padding: 2px 2px 2px 2px !important;
    background: #ff9152;
    border: 1px solid #fff;
    color: #fff;
    /* margin: -0.5rem -0.5rem -0.5rem auto; */
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    box-sizing: content-box;
    border-radius: 4px;
    margin-left: 5px;
}

.toggle-button-sideBar-1 {
    padding: 2px 2px 2px 2px !important;
    background: #ff7900;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    box-sizing: content-box;
    border-radius: 4px;
}

.sideBar-width-reset {
    width: 5.333333% !important;
    margin-left: 1%;
    box-shadow: 0 0 0px 3px hsla(0, 0%, 94%, 0.5);
}

.side-search i {
    font-size: 28px;
    padding-left: 5px;
    padding-top: 2px;
}

.side-search img {
    padding: 3px;
}

.lef_side_bar-1 {
    height: 873px;
    overflow: auto;
}

.lef_side_bar-1::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
}

.lef_side_bar-1::-webkit-scrollbar {
    width: 0px;
    background-color: white;
}

.sideBar-toggle-fixed {
    height: 88px;
    background-color: #ffffff;
    width: 42px;
}

.border-bottom-2px {
    position: absolute;
    top: 39px;
    left: -6px;
    border-bottom: 2px solid #e3dfdf;
    width: 50px;
}

.border-bottom-2px-1 {
    position: absolute;
    top: 85px;
    left: -6px;
    border-bottom: 2px solid #e3dfdf;
    width: 50px;
}

.padding-top-4px {
    padding-top: 4px;
}

.colorGreen {
    color: #55bd6c;
}

.colorRed {
    color: #f6685e;
}

.input_border:focus {
    box-shadow: none;
    border-color: #c1c1c1;
}

.admin-close {
    right: 41px !important;
}

.disabled-input {
    background-color: #e0e4e7;
}

.disabled-input:disabled {
    background-color: #e0e4e7;
}

.width_40 {
    width: 40%;
}

.width_60 {
    width: 60%;
}

.min-overview select {
    font-family: 'Montserrat', sans-serif;
    border: none;
    width: 70% !important;
}

.min-overview input {
    width: 70% !important;
}

.min-overview label {
    width: 30% !important;
}

select.know-commper-bx-min {
    border: 2px solid #ced5d9;
    padding: 3px 5px;
    border-radius: 3px;
    background-color: white;
    width: 151px !important;
}

select.know-commper-bx-min:focus-visible {
    border: 2px solid #ff8038;
    outline: none
}

.py-0px {
    padding: 0px 18px !important;
}

.file_upload {
    padding: 20px;
    width: 100%;
    height: 66px !important;
}

@media (min-width: 576px) {
    .modal-dialog-img {
        max-width: 1000px;
        margin: 1.75rem auto;
    }
}

.currency_code {
    font-size: 11px;
}

.currency_port {
    position: absolute;
    top: 32px;
    right: 45px;
}

.form-check-input {
    height: 1em !important;
}

.tradeType:checked {
    background-color: #ff8038 !important;
    border-color: #ff8038 !important;
}

.news_title {
    color: #2d5391;
}

.news_desc {
    color: #44474b;
}

.showMore {
    color: #2d5391;
    cursor: pointer;
}

.loading-Dot-stock_news {
    display: flex;
    position: absolute;
    bottom: -3px;
    left: -2px;
}


/* loader main */

.commission_model_size {
    max-width: 772px!important;
}

.openTrade_model_size {
    max-width: 860px !important;
}

.height_51px {
    height: 51px;
}

.option_stock {
    line-height: 18px;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 4px !important;
    color: #4c4949;
    cursor: pointer;
}

.p-10px {
    padding: 10px 10px 10px 10px;
}

.help_button {
    margin-top: 50px !important;
    width: 100%;
}


/* modal video  */

#Videoplayclick .title {
    width: 100%;
    max-width: 854px;
    margin: 0 auto;
}

#Videoplayclick .caption {
    width: 100%;
    max-width: 854px;
    margin: 0 auto;
    padding: 20px 0;
}

#Videoplayclick .vid-main-wrapper {
    width: 100%;
    max-width: 1100px;
    min-width: 440px;
    background: #fff;
    margin: 0 auto;
}


/*  VIDEO PLAYER CONTAINER
############################### */

#Videoplayclick .vid-container {
    position: relative;
    padding-bottom: 42%;
    padding-top: 30px;
    height: 0;
    width: 70%;
    float: left;
}

#Videoplayclick .vid-container iframe,
.vid-container object,
.vid-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 500px;
}


/*  VIDEOS PLAYLIST 
############################### */

#Videoplayclick .vid-list-container {
    width: 30%;
    height: 500px;
    overflow: hidden;
    float: right;
}

#Videoplayclick .vid-list-container:hover,
.vid-list-container:focus {
    overflow-y: auto;
}

#Videoplayclick ol#vid-list {
    margin: 0;
    padding: 0;
    background: #243a69;
}

#Videoplayclick ol#vid-list li {
    list-style: none;
}

#Videoplayclick ol#vid-list li a {
    text-decoration: none;
    background-color: #243a69;
    height: 73px;
    display: block;
    padding: 10px;
}

#Videoplayclick ol#vid-list li a:hover {
    background-color: #fff
}

#Videoplayclick ol#vid-list li a:hover .desc {
    color: #000
}

#Videoplayclick .vid-thumb {
    float: left;
    margin-right: 8px;
}

#Videoplayclick .active-vid {
    background: #3A3A3A;
}

#Videoplayclick #vid-list .desc {
    color: #CACACA;
    font-size: 13px;
    margin-top: 5px;
}

@media (max-width: 624px) {
    #Videoplayclick body {
        margin: 15px;
    }
    #Videoplayclick .caption {
        margin-top: 40px;
    }
    #Videoplayclick .vid-list-container {
        padding-bottom: 20px;
    }
}

#Videoplayclick .modal-lg,
.modal-xl {
    max-width: 1150px;
}

.vid-list-container::-webkit-scrollbar {
    background: #eee;
    height: 0.45rem;
    width: 0.3rem;
}

.vid-list-container::-webkit-scrollbar {
    background-color: #fff;
    width: 5px;
}

.vid-list-container::-webkit-scrollbar-thumb {
    background: #fd7e14;
    border-radius: 0;
}

.vid-list-container::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #cecece !important;
    opacity: 1;
}

@media (min-width: 992px) {
    .width_900 {
        max-width: 892px;
    }
}

.buyButton {
    background-color: #55bd6c;
}

.sellButton {
    background-color: #f6685e;
}

.buySell_main span {
    font-size: 11px;
}

.help_button {
    margin-top: 50px !important;
    width: 100%;
}

.menu-active-create {
    background-color: #ff8038;
    color: #fff !important;
}


/* VIDEO CSS */

#upload_Video .form-container {
    width: 100vw;
    height: 100vh;
    background-color: #7b2cbf;
    display: flex;
    justify-content: center;
    align-items: center;
}

#upload_Video .upload-files-container {
    padding: 13px 60px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#upload_Video .drag-file-area {
    background-color: #f7fff7;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
    border: 2px dashed #ff7900;
    border-radius: 40px;
    margin: 0px 0 0px;
    padding: 2px 26px;
    text-align: center;
}

#upload_Video .drag-file-area .upload-icon {
    font-size: 33px;
}

#upload_Video .drag-file-area h3 {
    font-size: 26px;
    margin: 15px 0;
}

#upload_Video .drag-file-area label {
    font-size: 19px;
    line-height: 0.9;
}

#upload_Video .dynamic-message {
    font-size: 13px;
}

#upload_Video .drag-file-area label .browse-files-text {
    color: #ff8038;
    font-weight: bolder;
    cursor: pointer;
}

#upload_Video .browse-files span {
    position: relative;
    top: -25px;
}

#upload_Video .default-file-input {
    opacity: 0;
}

#upload_Video .cannot-upload-message {
    background-color: #ffc6c4;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 5px 10px 5px 30px;
    border-radius: 5px;
    color: #BB0000;
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#upload_Video .cannot-upload-message span,
.upload-button-icon {
    padding-right: 10px;
}

#upload_Video .cannot-upload-message span:last-child {
    padding-left: 20px;
    cursor: pointer;
}

#upload_Video .file-block {
    color: #f7fff7;
    background-color: #7b2cbf;
    transition: all 1s;
    width: 390px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 15px;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
}

#upload_Video .file-info {
    display: flex;
    align-items: center;
    font-size: 15px;
}

#upload_Video .file-icon {
    margin-right: 10px;
}

#upload_Video .file-name,
.file-size {
    padding: 0 3px;
}

#upload_Video .remove-file-icon {
    cursor: pointer;
}

#upload_Video .progress-bar {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 4.5%;
    width: 0;
    height: 5px;
    border-radius: 25px;
    background-color: #4BB543;
}

#upload_Video .upload-button {
    font-family: 'Montserrat';
    background-color: #7b2cbf;
    color: #f7fff7;
    display: flex;
    align-items: center;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    margin: 10px;
    padding: 7.5px 50px;
    cursor: pointer;
}

.vid-container div {
    width: 100% !important;
    /* height: 100 !important; */
}

.iconbtn {
    background-color: #e3e3e3;
    border: none;
    color: #27416e;
    position: relative;
    right: -129px;
    bottom: 30px;
    padding: 0px;
    font-size: 14px;
    width: 23px;
    border-radius: 5px;
}

@media (min-width: 991px) and (max-width: 1180px) {
    .iconbtn {
        background-color: #e3e3e3;
        border: none;
        color: #27416e;
        position: relative;
        right: -110px;
        bottom: 30px;
        padding: 0px;
        font-size: 14px;
        width: 23px;
        border-radius: 5px;
    }
}


/*  */

.menu-active-create {
    background-color: #ff8038;
    color: #fff !important;
}

.discount_add .card.mb-3 {
    margin-bottom: 0px!important;
}

.discount_add .widget-chart {
    text-align: center;
    padding: 1rem;
    position: relative;
}

.discount_add .card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, .03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, .03), 0 0.25rem 0.53125rem rgba(4, 9, 20, .05), 0 0.125rem 0.1875rem rgba(4, 9, 20, .03);
    border-width: 0;
    transition: all .2s;
}

.discount_add .widget-chart .widget-chart-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 95px;
    width: 100%;
    opacity: .25;
    z-index: 4;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    overflow: hidden;
}

.discount_add .icon-wrapper {
    font-size: 40px;
}

.discount_add .widget-chart {
    text-align: center;
    padding: 1rem;
    position: relative;
}

.discount_add .apexcharts-canvas {
    position: relative;
    user-select: none;
}

.discount_add .widget-chart-content {
    position: relative;
    z-index: 9;
}

.discount_add .apexcharts-canvas {
    margin: 0 auto;
}

.discount_add .widget-chart-wrapper SVG {
    filter: invert(52%) sepia(103%) saturate(6%) hue-rotate(87deg) brightness(125%) contrast(119%);
}

.discount_add .Report_btn svg {
    margin-bottom: 0px;
    margin-top: -4px;
    font-size: 17px;
}

.discount_add .rounded svg {
    color: #243a69;
}

.discount_add .widget-numbers span {
    font-weight: 600;
    font-size: 2.0rem;
    display: block;
}

.discount_add .text-warning {
    color: #243a69;
    font-weight: 600;
}

.discount_add .widget-description.opacity-8 {
    font-size: 13px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .discount_add .card.mb-3 {
        margin-bottom: 15px!important;
    }
}


/* .min-data-drop-down p, span {
    font-family: Montserrat,sans-serif;
    font-size: 13px;
} */

.protfolio-sec .btn {
    padding: 15px 18px !important;
}


/* order History */

.order_history .main-head {
    background: #0D1440;
    box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
    padding: 1rem;
    margin-bottom: 0;
    margin-top: 0rem;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 16px;
}

.order_history .pricing-table {
    background: #fff;
    box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
    padding: 0px 20px;
    border-radius: 4px;
    transition: .3s;
}

.order_history .pricing-table:hover {
    box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, .15);
}

.order_history .pricing-table .pricing-label {
    border-radius: 2px;
    padding: .25rem .5rem;
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
}

.order_history .pricing-table h2 {
    color: #3b3b3b;
    font-size: 24px;
    font-weight: 500;
}

.order_history .pricing-table h5 {
    color: #B3B3B3;
    font-size: 14px;
    font-weight: 400;
}

.order_history .pricing-table .pricing-features {
    margin-top: 0rem;
}

.order_history .pricing-table .pricing-features .feature {
    font-size: 14px;
    margin: .5rem 0;
    color: #B3B3B3;
}

.order_history .pricing-table .pricing-features .feature p {
    /* display: inline-block;
    float: right; */
    color: #6c757d;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
}

.order_history .pricing-table .price-tag {
    margin-top: 2rem;
    text-align: center;
    font-weight: 500;
}

.order_history .pricing-table .price-tag .symbol {
    font-size: 24px;
}

.order_history .pricing-table .price-tag .amount {
    letter-spacing: -2px;
    font-size: 64px;
}

.order_history .pricing-table .price-tag .after {
    color: #3b3b3b;
    font-weight: 500;
}

.order_history .pricing-table .price-button {
    display: block;
    color: #fff;
    margin-top: 2rem;
    padding: .75rem;
    border-radius: 2px;
    text-align: center;
    font-weight: 500;
    transition: .3s;
}

.order_history .pricing-table .price-button:hover {
    text-decoration: none;
}

.order_history .pricing-table .pricing-features .feature {
    margin: 0;
    color: #314772;
    border-bottom: 0px solid #eee;
    padding: 0.0rem 0;
    font-weight: 600;
    text-transform: uppercase;
}

.scroll_order_history {
    overflow: auto;
    /* height: 500px; */
}

.modal_order_history {
    padding: 20px 0px;
}

.scroll_order_history::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}

.scroll_order_history::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}

.scroll_order_history::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}

.scroll_order_history::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}

.modal_order_history .btn-close-provider {
    position: absolute !important;
    top: 37px !important;
    right: 43px !important;
    padding: 3px 7px 10px 7px !important;
}

.price_center p {
    text-align: center;
}

.green1 {
    color: green !important;
    transform: translate(0px, 14px);
}

.red1 {
    color: red !important;
    transform: translate(0px, 14px);
}

.price_right p {
    text-align: end;
}

@media (min-width: 280px) and (max-width: 767px) {
    .order_history {
        padding: 0;
    }
    .modal_order_history {
        padding: 10px;
    }
    .order_history .pricing-table {
        padding: 0px 20px;
    }
    .modal_order_history .btn-close-provider {
        position: absolute !important;
        top: 28px !important;
        right: 30px !important;
        padding: 3px 7px 10px 7px !important;
    }
    .price_right p {
        text-align: end;
    }
}

.spinner {
    border: 2px solid #ccc;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.symb {
    font-size: smaller;
    color: #ffffff7a;
}

.buysellspan {
    color: #ff7900;
}

.modal-content {
    background: linear-gradient(45deg, #243a69f5, #243a69ed), url('/public/assets/image/24666.jpg');
    border-left: 5px solid #ffffff;
    border-right: 5px solid #ffffff;
    border-radius: 20px;
}

label {
    color: white;
}

.hetgit-505 {
    height: 505px !important;
}

.modal-content .white_text_next {
    color: white !important;
}

.tooltip {
    position: relative;
    /* display: inline-block; */
    display: table-cell;
    opacity: 1!important;
    font-weight: 500;
}

.tooltip:hover::before {
    content: attr(data-title);
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 1;
    transition: opacity 0.3s;
}

.tooltip:hover::before {
    opacity: 1;
}

td.tooltip.g {
    padding-left: 17px;
    z-index: 999;
}


/* td.tols_hover:hover .on_hvr {
    position: relative;
    transform: translate(0px, 13px);
    font-size: 9px;
    background: #ffc986;
    color: #000;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    border-radius: 5px;
    width: fit-content;
    padding: 0px 5px;
}

.on_hvr {
    display: none;
} */