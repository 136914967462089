* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/*=======global btn csss======*/

body {
    font-family: 'Roboto', sans-serif;
}

p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 2.2;
    text-align: justify;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3 !important;
    /*   color: #000;*/
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h5 {
    font-size: 16px;
}

ul {
    padding-left: 0rem;
}

.btn {
    padding: 5px 37px;
    background-color: #ff8038;
    /*    border-radius: 30px;*/
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    box-shadow: none !important;
}

.bt-light {
    background-color: #243a69;
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    letter-spacing: 1px;
    border: 1px solid #ff8038;
    transition: .25s;
    box-shadow: none;
    color: #fff;
}

.bt-light:hover {
    background-color: #ff8038;
    box-shadow: 0px 15px 20px rgba(255, 128, 56, 0.4);
    color: #fff;
    /* transform: translateY(-7px); */
    border: 1px solid #fff;
    box-shadow: none;
}

.fullc-or {
    background-color: #ff8038 !important;
}

.bt-drk {
    background-color: transparent !important;
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    letter-spacing: 1px;
    color: #ff8038;
    border: 1px solid #ff8038;
    transition: .25s;
    box-shadow: none;
}

.bt-drk:hover {
    background-color: #ff8038 !important;
    border: 1px solid #fff;
    box-shadow: none;
    box-shadow: 0px 15px 20px rgba(255, 128, 56, 0.4);
    color: #fff;
    transform: translateY(-7px);
}


/*=======global btn css======*/


/*.toggle-bar{
	   display: none;
}
*/


/*=======left-bar=======*/

.left-bar {
    border: 1px solid #ddd;
    position: fixed;
    width: 10%;
    /*	  height: 100%;*/
    left: 0;
    padding-top: 10px;
    border-top-right-radius: 10px;
    -webkit-appearance: none;
    box-shadow: 10px 10px 0px -9px rgba(0, 0, 0, 0.1);
}


/*.left-side {width:55%; height:800px; left:-10px;   position:sticky; top:0px;  box-shadow: 10px 10px 0px -9px rgba(0,0,0,0.1);
             border-top-right-radius: 10px;
	        -webkit-appearance: none;  border:1px  solid #ddd;}*/

.menu-sec i {
    font-size: 30px;
    color: #2f3b4d;
    line-height: 2;
    transition: .25s;
}

.menu-sec ul li {
    color: #000;
    text-align: center;
    list-style: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
}

.menu-sec a {
    color: #000;
    transition: .25s;
}

.menu-sec a:hover {
    color: #ff8038;
}

.menu-sec a:hover i {
    color: #ff8038;
}


/*=======left-bar=======*/


/*=======dashboard=======*/

.search input {
    height: 60px;
    text-indent: 32px;
    padding-top: 10px;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.search input:focus {
    box-shadow: none;
    border: 1px solid #ff8038;
}

.search {
    position: relative;
}

.search .bi-search {
    position: absolute;
    top: 20px;
    left: 16px;
    color: #000;
}

.select-bx {
    position: relative;
}

.select-bx select {
    max-width: 120px;
    position: absolute;
    right: 10px;
    height: 60px;
    border: none;
    box-shadow: none !important;
    font-weight: 700;
}

.user-img {
    position: absolute;
    right: 139px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-image: url(..//image/user.jpg);
    background-size: cover;
}

.protfolio-sec {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 28px 35px 40px;
}

.left-protfolio select {
    height: 55px;
    box-shadow: none !important;
    font-weight: 700;
}

.Balance-bx {
    background-color: #243a69;
    border-radius: 10px;
    color: #fff;
    padding: 4px 14px;
}

.Balance-bx h4 {
    font-size: 2rem;
}

.two-selctone select {
    height: 36px;
    box-shadow: none !important;
    font-weight: 700;
}

.Portfolio-tablesec h6 {
    color: #ff8038;
}

.orc-table .table-dark th {
    background-color: #ff8038 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}

.mid-protfolio {
    position: relative;
}


/*.img-w-titel img{
              position: relative;
       }*/

.img-w-titelbx h1 {
    text-transform: capitalize;
    font-size: 1.2rem;
}

.img-w-titelbx1 h1 {
    font-size: 2rem;
    font-family: 'Nunito', sans-serif;
}

.img-w-titelbx2 h1 {
    text-transform: capitalize;
    font-size: 1.3rem;
}

.img-w-titelbx3 h1 {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Nunito', sans-serif;
}

.img-w-titelbx,
.img-w-titelbx1,
.img-w-titelbx2,
.img-w-titelbx3 {
    position: relative;
}

.img-w-titelbx h1,
.img-w-titelbx1 h1,
.img-w-titelbx2 h1,
.img-w-titelbx3 h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0 !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 1rem;
    }
    .img-w-titelbx1 h1 {
        font-size: 1.6rem;
    }
}

@media (max-width: 420px) {
    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 14px;
    }
    .img-w-titelbx1 h1 {
        font-size: 22px;
    }
}


/*=======dashboard=======*/


/*=======modal-content=======*/

.modal-content {
    padding: 30px;
}

.blackout-bx a {
    color: #000;
}

.rate-bx {
    padding: 14px 30px 14px 30px;
    background-color: #243a69;
    border-radius: 10px;
    color: #fff;
}

.rate-bx span {
    font-size: 1.5rem;
}

.form-bx input {
    height: 50px;
    border-radius: 5px;
    box-shadow: none !important;
}

.form-bx input:focus {
    border: 1px solid #ff8038;
    ;
}

.form-bx select {
    height: 50px;
    border-radius: 5px;
    box-shadow: none !important;
}

.form-bx select:focus {
    border: 1px solid #ff8038;
    ;
}

.value-bx1 span {
    background-color: #fff;
    color: #000;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
}

.rate-bx1 {
    padding: 20px 10px 10px 10px;
    background-color: #243a69;
    border-radius: 10px;
    color: #fff;
}

.in-w-slec input {
    max-width: 600px;
}

.in-w-slec select {
    max-width: 200px;
}

.add-btn {
    box-shadow: none !important;
    color: #ff8038;
    font-weight: 600;
}

.cansel-btn {
    padding: 10px 80px 10px 80px;
    background-color: transparent;
    color: #ff8038;
    border: 1px solid #ff8038;
}

.save-btn {
    padding: 10px 80px 10px 80px;
    background-color: #ff8038;
    border: 1px solid #ff8038;
    color: #fff;
    transition: .25s;
}

.save-btn:hover {
    background-color: #243a69;
    color: #fff;
}

.menu {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 7px 22px;
    border: 0;
    border-radius: 50px;
    line-height: 1.5em;
    color: black;
    text-decoration: none;
    font-size: 15px;
    background: white;
    list-style: none;
    box-shadow: 8px 8px 14px 0px rgb(50 50 50 / 11%);
}

.menu-active {
    background-color: #ff8038;
    color: #fff !important;
}

.blackout-bx.modal-header {
    border-bottom: none !important;
}

.btn-close {
    font-size: 19px;
    font-weight: 600;
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    opacity: 1;
    background-image: none;
}

.value-bx1 {
    position: relative;
}


/*=======modal-content=======*/


/*=========foter==========*/

footer {
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: #233a69;
    z-index: 9999999;
    position: relative;
    bottom: 0;
}

footer p {
    color: #fff;
}

.social i {
    padding: 10px 12.5px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    transition: .25s;
    border-left-style: dotted;
    transition: .25s;
}

.social i:hover {
    background-color: #ff8038;
}


/*=========foter==========*/

@media only screen and (max-width:350px) {
    /*.toggle-bar{
	    display: block;
}
*/
    .left-bar {
        position: relative;
        width: 50%;
    }
}


/*calucaltor*/

.trade {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 32px 0px rgba(36, 40, 46, .12);
}

.trade h2 {
    color: #ff9152;
}

.frm-hd-t {
    padding: 9px 29px;
    background-color: #ff9152;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
}

.frm-hd-t1 {
    padding: 12px 29px;
}

.postion-frm {
    border: 1px solid #cbd0dd;
    padding: 20px 20px 10px 20px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.postion-frm label {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #525b75;
}

.postion-frm input {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.postion-frm input:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.postion-frm select {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.postion-frm select:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.radio-input input {
    height: 13px !important;
    margin-top: 6px;
}

.radio-input .form-check-input:checked {
    background-color: #ff8038 !important;
    border: 1px solid #ff8038 !important;
}

.radio-input label {
    padding-right: 10px;
    padding-left: 7px;
}

.commi-btn {
    padding: 8px 25px;
    background-color: #ff8038 !important;
    color: #525b75;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.commi-btn:hover {
    color: #fff;
    box-shadow: 0px 5px 20px rgba(255, 128, 56, 0.4);
}

.table-input {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    text-transform: capitalize;
    border: 1px solid #cbd0dd;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.table-input input {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    transition: .25s;
}

.table-input input:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.table-input span {
    padding: 10px;
    font-size: 13px;
}

.table-input td {
    border: none;
}


/*///////////*/


/*summery*/

.tabs-sec {
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
}

.tabs-sec .nav-item .nav-link {
    border: 0;
    height: 60px;
    background-color: #f4f1f1;
    border-radius: 0px;
    color: #000;
    border-right: 1px solid #e9e6e6;
}

.tabs-sec .nav-pills .nav-link.active {
    background-color: #ff9152 !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffff;
}

.table-input1 {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    text-transform: capitalize;
    /*	   border:1px solid #cbd0dd;*/
    /* border-top: none; */
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}


/*////////*/

.padding-ds {
    padding: 10px 20px;
    background-color: #fed9d9;
    border-radius: 5px;
    color: #e37676;
}

.padding-btn-dtd img {
    max-width: 20px;
    padding-right: 5px;
}

.padding-btn-dtd {
    padding: 5px 17px;
    background-color: #54ffda;
    text-transform: capitalize;
    font-size: 11px;
}

.padding-btn-dtd:focus {
    box-shadow: none;
}

.edit-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.delete-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.open-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.watch {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}


/*admin css*/

.admin-table table {
    border-radius: 10px;
}

.admin-table th,
tr {
    padding: 15px 10px 15px 10px;
}

.data-import select {
    border: 1px solid #8392ab;
    box-shadow: none !important;
    border-radius: 5px;
}


/*admin css*/

.edit-btn-adm {
    border: 1px solid #dee2e6;
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    border-radius: .375rem;
    font-size: 12px;
    padding: 6px 13px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
}

.edit-btn-adm:hover {
    background-color: #eaeaea;
}

.delete-admin {
    border: 1px solid #dee2e6;
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    border-radius: 0.375rem;
    font-size: 14px;
    /* padding: 7px 12px; */
    color: #f00;
}

.delete-admin:hover {
    color: #000;
}

.adm-btn {
    padding: 8px 20px;
    background-color: #bbc6ff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    box-shadow: none !important;
}

.import-bx {
    border: 1px dashed #8392ab;
    text-align: center;
    padding: 40px 20px 40px 20px;
    border-radius: 10px;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12) !important;
}

.import-bx span {
    padding: 10px 21px;
    background-color: #BDCDEF;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    border-radius: 30px;
}

.drop {
    padding: 12px 14px;
    background: #FF9E46;
    border-radius: 30px;
    color: #fff;
}

.import-bx input {
    margin-top: 20px;
    background: #fff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
}

.ad-tab-b {
    border-bottom: 1px solid #cbd0dd !important;
}


/*//////*/

.company-lg img {
    max-width: 60px;
    border-radius: 5px;
    border: 1px solid #e3e6ed;
    box-shadow: 0 4px 12px rgba(86, 141, 187, .12);
}

.cp-tail-bx {
    position: relative;
}

.bell-bx {
    position: absolute;
    top: 0;
    right: 40px;
}

.cp-tail-bx ul li {
    list-style: none;
    display: inline-block;
    padding: 9px 13px;
    border: 1px solid #e5faf5;
    border-radius: 30px;
    margin-right: 7px;
    border: 1.26923px solid 1px solid #00d09c;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(86, 141, 187, .12);
}

.cp-tail-bx a {
    color: #FF9E46;
}

.cp-tail-bx h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
}

.cp-tail-bx p {
    font-family: 'Roboto', sans-serif;
}

.cp-tail-bx h5 {
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
}

.cp-tail-bx-asd h5 {
    font-size: 21px !important;
    padding-top: 19px;
    padding-left: 10px;
}

.cp-tail-bx span {
    font-size: 15px;
    color: #eb5b3c;
}

.id {
    color: #a0a0a0 !important;
}

.optino-btn-td {
    border: 1px solid #FF9E46;
    border-radius: 30px;
    padding: 5px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #FF9E46;
    background-color: #ffffff;
    position: absolute;
    right: -27px;
    top: 94px;
}

.grp-img img {
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    margin-bottom: 40px;
}

.bot-brd {
    border-top: 1px dashed #7c7e8c;
    width: 100%;
    height: 1px;
    background-color: transparent;
}

.detels-bx .nav-pills .nav-link.active {
    color: #fff;
    background: linear-gradient(51deg, rgba(36, 58, 105, 1) 3%, rgba(77, 102, 156, 1) 105%);
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.detels-bx .nav-item .nav-link {
    height: 50px;
    border-radius: 0px;
    background-color: transparent;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 800;
}

.new-bx {
    border: 1px dashed #afbcd2;
    padding: 10px 20px;
    height: 107px;
    border-radius: 10px;
    position: relative;
    box-shadow: rgb(143 187 245 / 35%) 7px 7px 0 0;
}

.new-bx span {
    padding: 6px 0px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
}

.new-bx h5 {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.5 !important;
    color: #4b4747;
}

.slec-dta-ch li {
    padding: 1px 20px;
    background-color: #BDCDEF;
    display: inline-block;
    border-radius: 20px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 3px 6px #dfdada;
    transition: .25s;
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 2px;
}

.slec-dta-ch li:hover {
    box-shadow: 0px 0px 0px #999;
}

.slec-dta-ch a {
    color: #000;
    /*    text-shadow: 1px 1px 5px #999;*/
}

.slec-dta-ch {
    position: absolute;
    right: 0px;
    top: 381px;
    z-index: 1;
}

label.countrylabel {
    margin-top: 6px;
}


/*Performance data code*/

.overview-sec {
    position: relative;
}

.pr-mbx {
    border: 1px solid #FF9E46;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0px 1px 9px rgba(255, 128, 56, 0.4);
    position: relative;
}

.pin-x {
    padding: 7px 12px;
    background-color: #ff7900;
    position: absolute;
    right: 12px;
    font-size: 18px;
    border-radius: 10px;
    color: #fff;
    top: 15px;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, .12);
}

.apt-bx {
    width: 120px;
    height: 111px;
    background-color: #BDCDEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #ffffff;
}

.analyst-bx ul li {
    line-height: 2;
}

.progress {
    background-color: #e3e6ef;
    height: 10px;
    border-radius: 30px;
}

.progress-bar {
    background-color: #FF9E46;
}

.Fundamentals-bx {
    border: 1px solid #ddd;
    /* padding: 0px 10px 20px 10px; */
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
}

.financials-bx {
    border: 1px dashed #ddd;
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
}

#more {
    display: none;
}

.shareholding-bx {
    position: relative;
}

.sh-ul {
    top: 0 !important;
    right: 0;
}

.news-ul {
    position: relative;
}

.new-ul {
    top: 0 !important;
    right: 0 !important;
}


/*Performance data code*/


/*calutero model*/

.cal-model {
    padding: 10px;
}

.postion-min-dta {
    border: 1px solid #cbd0dd;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.postion-min-dta label {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    /* color: #525b75; */
}

.postion-min-dta input {
    font-size: 12px;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    height: 35px;
    border-radius: 3px;
}

.calu-bx-modl select {
    height: 35px;
    border-radius: 3px;
    font-size: 12px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.calu-bx-modl select:focus {
    border: 1px solid #ff9152;
    box-shadow: none;
}

.commi-btn-link {
    background-color: transparent;
    text-transform: capitalize;
    padding: 0px;
}

.commi-btn-link a {
    text-decoration: underline;
}

.provider-min-md {
    padding: 2px;
}

.provider-datafd {
    font-size: 10.5px;
}

button.btn-close {
    position: absolute;
    top: 42px;
    right: 6px;
    padding: 3px 7px 9px 7px;
    background: #ff7900;
    border: 1px solid #fff;
    color: #fff;
}


/*calutero model*/

.new-bx p {
    color: #000;
    font-size: 14px;
}

span.doller-d {
    position: absolute;
    left: 6px;
    background: transparent;
    top: 57px;
    /* padding-right: 20px; */
}

span.min-d-bx {
    position: relative;
}

.value-bx1 input {
    padding-left: 20px;
    height: 35px;
}

.d-flex.position-relative img {
    height: 80px;
    width: 120px;
}


/* responsive sec */

@media only screen and (min-width:768px) and (max-width:1024px) {
    .right-protfolio {
        margin-top: 5%;
    }
    .modal-dialog {
        max-width: 690px;
        margin: 0.5rem auto;
    }
}

@media only screen and (min-width:1024px) and (max-width:1100px) {
    .right-protfolio {
        margin-top: 5%;
    }
    .modal-dialog {
        max-width: 690px;
        margin: 0.5rem auto;
    }
}


/* comment css*/

.comments-input input {
    height: 80px;
}


/*trans*/

.postion-frm textarea {
    height: 80px;
    box-shadow: none !important;
    transition: .25s;
}

.postion-frm textarea:focus {
    border: 1px solid #ff9152;
    box-shadow: 0px 1px 9px rgba(255, 128, 56, 0.4) !important;
}

.ancer-k {
    color: blue !important;
    text-transform: capitalize !important;
    text-decoration: underline;
}

.com_na {
    background-color: transparent;
    color: #0056ff;
    padding: 0;
    text-decoration: underline;
}


/* start 05-06-2023 */

.a .dropdown {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.a .dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.a .dropdown-body {
    padding: 5px;
    border: 1px solid #dbc3c3;
    display: none;
    border-radius: 5px;
}

.a .dropdown-body.open {
    display: block;
    position: absolute;
    background-color: white;
    width: inherit;
    margin-top: 1px;
    z-index: 1;
}

.a .dropdown-item {
    padding: 10px;
    overflow-x: hidden;
}

.a .dropdown-item:hover {
    cursor: pointer;
}

.a .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.a .dropdown-item-dot.selected {
    opacity: 1;
}

.a .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.a .icon.open {
    transform: rotate(90deg);
}

.a .dropdown-item:active {
    color: #1e2125;
    background-color: #e9ecef;
}

@media (max-width: 767px) {
    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 14px;
    }
    .img-w-titelbx1 h1 {
        font-size: 22px;
    }
    .openttrade_transtion {
        overflow-x: scroll;
        width: 700px;
    }
    .openttrade_transtion .table-responsive {
        /* overflow-x: auto; */
        overflow: scroll;
        width: 830px;
    }
}

@media (max-width: 420px) {
    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 14px;
    }
    .img-w-titelbx1 h1 {
        font-size: 22px;
    }
    .openttrade_transtion {
        overflow-x: scroll;
        width: 600px;
    }
    .openttrade_transtion .table-responsive {
        /* overflow-x: auto; */
        overflow: scroll;
        width: 830px;
    }
}

.postion-frm .white_text {
    color: white !important;
}

.white_text {
    color: white !important;
}


/*end  start 05-06-2023 */

.watchview {
    background-color: #dfdede;
    color: #000;
    text-transform: capitalize;
    padding: 4px 4px 4px 11px;
    margin-right: 12px;
}

.open-btnview {
    background-color: #dfdede;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

img.img-fluid.size {
    height: 16px;
}

h4.herofont {
    font-size: 27px;
}

.watchWhite {
    background-color: #324672;
    text-transform: capitalize;
    padding: 2px 0px 0px 25px;
    margin-right: 0;
}