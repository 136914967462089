.loading-main-dot{
    position: relative;
}
.loading-Dot-stock{
  display: flex;
    position: absolute;
    bottom: 23px;
    left: 334px;
}
.loading-Dot{
    display: flex;
    position: absolute;
    top: 20px;
    right: 10px;
}
.dot1, .dot2, .dot3 {
    background: #fff;
    width: 10px;
    height: 10px;
     border:double;
     border-color:#ffffffd4;
    border-radius: 50%;
    margin: 5px;
  }
  
  .dot1 {
    animation: jump 1.6s -0.32s linear infinite;
    background: #ff7900;
  }
  .dot2 {
      animation: jump 1.6s -0.16s linear infinite;
      background: #ff7900;
  }
  .dot3 {
      animation: jump 1.6s linear infinite;
      background: #ff7900;
  }
  
  @keyframes jump {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
    }
  }

  .loading-Dot-stock-excel {
    display: flex;
    position: absolute;
    bottom: -3px;
    left: 85px;
}
  .dot11, .dot21, .dot31 {
    margin: 5px;
  }

  .dot11 {
    animation: jump 1.6s -0.32s linear infinite;
    
  }
  .dot21 {
      animation: jump 1.6s -0.16s linear infinite;
      
  }
  .dot31 {
      animation: jump 1.6s linear infinite;
      
  }
  
  @keyframes jump {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
    }
  }

  