.bg-1{background: linear-gradient(to right,#7b4379,#dc2430);}
.bg-2{background:#ABAD5D;}
.bg-3{background:#1ABC9C;}
/********************  Preloader Demo-1 *******************/
.preloader1,.preloader1 .loader{display:flex;align-content:center}
.preloader1{height:100%;width:100%;padding:20px 0;flex-flow:column wrap;justify-content:center;align-items:center;perspective:700}
.preloader1 .loader{text-align:center;margin:5px;border-radius:50%;border:4px solid black;flex-flow:column wrap;justify-content:center;align-items:center;transform-style:preserve-3d;position:relative}
.preloader1 .preloader1 .loader-inner-1{animation:change_first_circle 2s ease-in-out infinite}
.preloader1 .loader-inner-2{animation:change_second_circle 2s ease-in-out infinite}
.preloader1 .loader-inner-3{width:100px;height:100px;animation:change_last_circle 3s linear infinite}
@keyframes change_first_circle{
    50%{transform:rotateX(360deg) scale(.8)}
}
@keyframes change_second_circle{
    50%{transform:rotateY(360deg) scale(.8)}
}
@keyframes change_last_circle{
    50%{transform:rotateX(360deg) scale(.8)}
}

